import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import CaseStudyHero from "~components/molecules/case_study/case_study_hero/case_study_hero";
import ChapterPrevNextButtons from "~components/molecules/chapter_prev_next_buttons/chapter_prev_next_buttons";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import SRSection from "~components/molecules/specialised_report/sr_section/sr_section";
import TOCNav from "~components/organisms/toc_nav/toc_nav";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import useIntersect from "~hooks/use_intersect/use_intersect";
import usePageData from "~hooks/use_page_data/use_page_data";
import { ASSET, BLOK_ARRAY, MEDIA, STORYBLOK_ENTRY } from "../../prop_types";
import RscHbEntryCTA from "../template_blog_article/components/resource_hub_entry_cta/resource_hub_entry_cta";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const TEMPLATE_COMPONENTS = {
  specialisedReportSection: SRSection,
};

export default function TemplateCaseStudy(props) {
  const [activeItem, setActiveItem] = useState(null);
  const { story, pageContext, entryAssets, data } = usePageData(props);

  const {
    subNavData,
    UIStrings,
    next,
    previous,
    pagePath,
    resourceHubCtaConfig,
    contactForm,
    lang,
    currentStudyIndex,
  } = pageContext;

  const allCaseStudies = data?.caseStudies?.nodes || {};

  const { children, headerColor, downloadFormId, hero: heroArray, cta } = story;
  const [hero] = heroArray || [];
  const sections =
    children?.length > 0 &&
    children.filter((child) => {
      return child.component === "specialisedReportSection";
    });

  const [bodyIORef, bodyIOEntry] = useIntersect({
    rootMargin: "-320px 0px 0px 0px",
  });

  const shouldShowTOCNav = bodyIOEntry.isIntersecting;

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      {...props}
      headerColor={headerColor}
    >
      {hero && <CaseStudyHero {...hero} />}
      <TOCNav
        pagePath={pagePath}
        sections={sections}
        chapters={allCaseStudies}
        currentChapterIndex={currentStudyIndex}
        isVisible={shouldShowTOCNav}
        activeItem={activeItem}
        downloadFormId={downloadFormId}
        allChaptersTitle="All case studies"
      />
      <div ref={bodyIORef}>
        {children?.length > 0 &&
          children.map((childComponent) => {
            return renderDynamicComponent({
              parentComponentName: "TemplateCaseStudy",
              componentName: childComponent.component,
              componentsMap: TEMPLATE_COMPONENTS,
              callbackWhenInView: setActiveItem,
              ...childComponent,
            });
          })}
      </div>
      <Contain>
        <ChapterPrevNextButtons
          previousTitle={UIStrings?.uiPreviousTitle}
          previousDescription={previous?.title}
          nextTitle={UIStrings?.uiNextTitle}
          nextDescription={next?.title}
          next={next}
          previous={previous}
        />
      </Contain>
      <Contain small>
        <RscHbEntryCTA cta={cta} resourceHubCtaConfig={resourceHubCtaConfig} />
      </Contain>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateCaseStudy.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
    caseStudies: PropTypes.shape({}),
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    body: BLOK_ARRAY,
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
    lang: PropTypes.string,
    heroArtwork: MEDIA,
    next: STORYBLOK_ENTRY,
    previous: STORYBLOK_ENTRY,
    UIStrings: PropTypes.shape({
      uiNextTitle: PropTypes.string,
      uiPreviousTitle: PropTypes.string,
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  fragment allCaseStudies on Query {
    caseStudies: allStoryblokEntry(
      filter: { id: { in: $allCaseStudyIds } }
      sort: { fields: sort_by_date }
    ) {
      nodes {
        id
        full_slug
        field_title_string
        field_subtitle_string
      }
    }
  }
  fragment allCaseStudyAssets on Query {
    assets: allFile(filter: { id: { in: $allAssets } }) {
      nodes {
        id
        publicURL
        fields {
          aspectRatio
        }
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query CaseStudyQuery(
    $id: String
    $langRegex: String
    $allAssets: [String]
    $allCaseStudyIds: [String]
  ) {
    ...story
    ...allCaseStudyAssets
    ...allCaseStudies
    ...global
  }
`;
