import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import { wrapperStyle } from "./sr_heading.module.scss";

function SRHeading({ preHeading, mainHeading }) {
  return (
    <div className={wrapperStyle}>
      <Contain small isTextCentered>
        {preHeading && (
          <Heading
            balanceText
            visualLevel={7}
            seoLevel={3}
            color="gray"
            isUppercase
          >
            {preHeading}
          </Heading>
        )}
        {mainHeading && (
          <Heading balanceText visualLevel={1} seoLevel={preHeading ? 4 : 3}>
            {mainHeading}
          </Heading>
        )}
      </Contain>
    </div>
  );
}

SRHeading.propTypes = {
  preHeading: PropTypes.string,
  mainHeading: PropTypes.string.isRequired,
};
SRHeading.defaultProps = {
  preHeading: null,
};

export default SRHeading;
