import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import QuoteLargeCenterAligned from "~components/molecules/quote/quote_large_center_aligned/quote_large_center_aligned";
import { BLOK_ARRAY } from "../../../../prop_types";

function SRQuoteContent({ quoteBody, quoteAttribution }) {
  return (
    <Contain>
      <QuoteLargeCenterAligned
        theme="dark"
        quoteAttribution={quoteAttribution}
        quoteBody={quoteBody}
      />
    </Contain>
  );
}

SRQuoteContent.defaultProps = {
  quoteBody: "Lorem ipsum dolor demit",
  quoteAttribution: null,
};

SRQuoteContent.propTypes = {
  quoteBody: PropTypes.string,
  quoteAttribution: BLOK_ARRAY,
};

export default SRQuoteContent;
