import React from "react";
import PropTypes from "prop-types";
import RscHbCtaLink from "~components/molecules/rsc_hb_cta/rsc_hb_cta_link/rsc_hb_cta_link";
import RscHbCtaNewsletter from "~components/molecules/rsc_hb_cta/rsc_hb_cta_newsletter/rsc_hb_cta_newsletter";
import { BLOK_ARRAY } from "../../../../prop_types";

function RscHbEntryCTA({ cta, resourceHubCtaConfig }) {
  const [ctaBlok] = cta || [];

  if (ctaBlok?.component === "ctaLink") {
    return <RscHbCtaLink {...ctaBlok} />;
  }
  if (ctaBlok?.component === "ctaNewsletterSignup") {
    return <RscHbCtaNewsletter {...ctaBlok} />;
  }

  const { ctaSignUp } = resourceHubCtaConfig || {};

  return <RscHbCtaLink {...ctaSignUp} />;
}

RscHbEntryCTA.propTypes = {
  cta: BLOK_ARRAY,
  resourceHubCtaConfig: PropTypes.shape({
    ctaSignUp: PropTypes.shape({}),
  }),
};

RscHbEntryCTA.defaultProps = {
  cta: null,
  resourceHubCtaConfig: null,
};

export default RscHbEntryCTA;
