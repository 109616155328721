import React, { useCallback, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import Gradient from "~components/atoms/gradient/gradient";
import Heading from "~components/atoms/heading/heading";
import DebouncedFocusWrapper from "~components/utils/debounced_focus_wrapper/debounced_focus_wrapper";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import { LINK } from "../../../../prop_types";
import Button from "../../button/button/button";
import {
  imageStyle,
  innerStyle,
  isWhiteStyle,
  outerWrapperStyle,
  wrapperStyle,
} from "./rsc_hb_cta_link.module.scss";

const CTA_BREAKERS = {
  default: "/images/resource_hub/resource_hub_default_breaker.png",
  people: "/images/resource_hub/resource_hub_culture_breaker.png",
  developers: "/images/resource_hub/resource_hub_engineering_breaker.png",
};

export default function RscHbCtaLink({ title, text, theme, link, buttonText }) {
  const [isActive, setIsActive] = useState(false);

  const setActive = useCallback(() => {
    setIsActive(true);
  }, []);
  const setInactive = useCallback(() => {
    setIsActive(false);
  }, []);

  const shouldUseDarkText = !theme || theme === "people";
  const textColor = shouldUseDarkText ? "cloudBurst" : "white";
  const innerClassNames = classNames(innerStyle, {
    [isWhiteStyle]: textColor === "white",
  });

  return (
    <DebouncedFocusWrapper
      callbackWhenFocused={setActive}
      callbackWhenBlurred={setInactive}
    >
      <div data-testid="rsc-hb-cta-link" className={outerWrapperStyle}>
        <LinkWrapper link={link}>
          <Card
            stretch
            color={theme}
            isFullWidth
            overflowHidden
            isRaised={isActive}
          >
            <Gradient gradient={theme} />
            <div className={wrapperStyle}>
              <div className={innerClassNames}>
                <Heading color={textColor} visualLevel="4" seoLevel="4">
                  {title}
                </Heading>
                <p>{text}</p>
                <div>
                  <Button hasChevron color="yellow" isActive={isActive}>
                    {buttonText}
                  </Button>
                </div>
              </div>
              <img
                className={imageStyle}
                alt="Background"
                src={CTA_BREAKERS[theme] || CTA_BREAKERS.default}
              />
            </div>
          </Card>
        </LinkWrapper>
      </div>
    </DebouncedFocusWrapper>
  );
}

RscHbCtaLink.propTypes = {
  buttonText: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.string,
  link: LINK,
};
RscHbCtaLink.defaultProps = {
  link: {
    url: "",
    linktype: "story",
    fieldtype: "multilink",
    cached_url: "en-gb/contact",
  },
  text: "Easily integrate next-generation payments and financial data into any app. Build powerful products your customers love.",
  theme: "blue",
  title: "Instant access to open banking",
  buttonText: "Book a demo",
};
