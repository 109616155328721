import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import { MEDIA } from "../../../../../../prop_types";
import {
  hasPaddingStyle,
  headingWrapperStyle,
  imageInnerStyle,
} from "./sr_image_wrapper_inner.module.scss";

const HEADING_MARGIN = { marginBottom: "none" };

function SRImageWrapperInner({ image, minWidth, title, hasPadding }) {
  const imageClassNames = classNames(imageInnerStyle, {
    [hasPaddingStyle]: hasPadding,
  });
  return (
    <>
      {title && (
        <div className={headingWrapperStyle}>
          <Heading
            color="gray"
            isUppercase
            isCentered
            margin={HEADING_MARGIN}
            visualLevel={7}
            seoLevel={4}
            balanceText
          >
            {title}
          </Heading>
        </div>
      )}
      <div className={imageClassNames}>
        <Image isWidthConstrained={false} width={minWidth} image={image} />
      </div>
    </>
  );
}

SRImageWrapperInner.propTypes = {
  image: MEDIA,
  minWidth: PropTypes.string,
  title: PropTypes.string,
  hasPadding: PropTypes.bool,
};

SRImageWrapperInner.defaultProps = {
  image: null,
  minWidth: null,
  title: null,
  hasPadding: false,
};

export default SRImageWrapperInner;
