import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "~components/molecules/background_image/background_image";
import { BLOK_ARRAY, COLOR, MEDIA } from "../../../../prop_types";
import { heroWrapperStyle } from "./case_study_hero.module.scss";
import CaseStudyHeroInner from "./components/case_study_hero_inner/case_study_hero_inner";

function CaseStudyHero({
  backgroundImage,
  h1SubtitleText,
  h2Title,
  connectedProducts,
  connectedProductsText,
  textColor,
  clientLogo,
  trueLayerLogo,
}) {
  return (
    <div className={heroWrapperStyle}>
      <BackgroundImage image={backgroundImage} objectFit="cover" />
      <CaseStudyHeroInner
        {...{
          h1SubtitleText,
          h2Title,
          connectedProducts,
          connectedProductsText,
          textColor,
          clientLogo,
          trueLayerLogo,
        }}
      />
    </div>
  );
}

CaseStudyHero.propTypes = {
  backgroundImage: MEDIA,
  h1SubtitleText: PropTypes.string,
  h2Title: PropTypes.string,
  connectedProductsText: PropTypes.string,
  connectedProducts: BLOK_ARRAY,
  clientLogo: BLOK_ARRAY,
  trueLayerLogo: BLOK_ARRAY,
  textColor: COLOR,
};

CaseStudyHero.defaultProps = {
  backgroundImage: null,
  h1SubtitleText: null,
  connectedProducts: null,
  connectedProductsText: null,
  h2Title: null,
  textColor: null,
  clientLogo: null,
  trueLayerLogo: null,
};

export default CaseStudyHero;
