import React from "react";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import RichTextWithBloks from "~components/molecules/rich_text/rich_text_with_bloks";
import { BLOK_ARRAY } from "../../../../prop_types";
import {
  cardInnerStyle,
  headingStyle,
  wrapperStyle,
} from "./sr_key_takeaways.module.scss";

function SRKeyTakeaways({ heading, keyTakeaways: keyTakeawaysArray }) {
  const [keyTakeaways] = keyTakeawaysArray || [];

  if (keyTakeaways) {
    return (
      <div className={wrapperStyle}>
        <Contain>
          <Card isFullWidth>
            <div className={cardInnerStyle}>
              {heading && (
                <div className={headingStyle}>
                  <Heading
                    color="azure"
                    visualLevel={4}
                    seoLevel={4}
                    isCentered
                  >
                    {heading}
                  </Heading>
                </div>
              )}
              <Contain extraSmall>
                <RichTextWithBloks {...keyTakeaways} />
              </Contain>
            </div>
          </Card>
        </Contain>
      </div>
    );
  }

  return null;
}

SRKeyTakeaways.propTypes = {
  heading: PropTypes.string,
  keyTakeaways: BLOK_ARRAY.isRequired,
};

SRKeyTakeaways.defaultProps = {
  heading: "",
};

export default SRKeyTakeaways;
