import React, { useEffect } from "react";
import PropTypes from "prop-types";
import slugify from "slugify";
import SectionAlternatingLayout from "~components/sections/section_alternating_layout/section_alternating_layout";
import SectionStats from "~components/sections/section_stats/section_stats";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import useIntersect from "~hooks/use_intersect/use_intersect";
import { BLOK_ARRAY } from "../../../../prop_types";
import SRBodyText from "../sr_body_text/sr_body_text";
import SRHeading from "../sr_heading/sr_heading";
import SRImageWrapper from "../sr_image_wrapper/sr_image_wrapper";
import SRKeyTakeaways from "../sr_key_takeaways/sr_key_takeaways";
import SRPhoneMockup from "../sr_phone_mockup/sr_phone_mockup";
import SRQuoteBanner from "../sr_quote_banner/sr_quote_banner";
import SRQuoteContent from "../sr_quote_content/sr_quote_content";
import SRVideoPlayer from "../sr_video_player/sr_video_player";
import { wrapperStyle } from "./sr_section.module.scss";

const TEMPLATE_COMPONENTS = {
  keyTakeaways: SRKeyTakeaways,
  sectionAlternatingLayout: SectionAlternatingLayout,
  sectionStats: SectionStats,
  specialisedReportBodyText: SRBodyText,
  specialisedReportHeading: SRHeading,
  specialisedReportImageWrapper: SRImageWrapper,
  specialisedReportPhoneMockup: SRPhoneMockup,
  specialisedReportQuoteBanner: SRQuoteBanner,
  specialisedReportQuoteContent: SRQuoteContent,
  specialisedReportVideoPlayer: SRVideoPlayer,
};
export default function SRSection({ title, children, callbackWhenInView }) {
  const id = slugify(title, { lower: true, strict: true });

  const [ref, inView] = useIntersect({ rootMargin: "-25% 0% -50% 0%" });

  useEffect(() => {
    if (inView?.isIntersecting) {
      callbackWhenInView(title);
    }
  }, [callbackWhenInView, id, inView, title]);

  if (children.length > 0) {
    return (
      <section ref={ref} id={id} className={wrapperStyle}>
        {children.map((childComponent) => {
          return renderDynamicComponent({
            parentComponentName: "SRSection",
            componentName: childComponent.component,
            componentsMap: TEMPLATE_COMPONENTS,
            ...childComponent,
          });
        })}
      </section>
    );
  }

  return null;
}

SRSection.defaultProps = {
  title: null,
  children: null,
  callbackWhenInView: () => {},
};

SRSection.propTypes = {
  title: PropTypes.string,
  children: BLOK_ARRAY,
  callbackWhenInView: PropTypes.func,
};
