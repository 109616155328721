import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import { BLOK_ARRAY, COLOR } from "../../../../../../prop_types";
import CaseStudyHeroConnectedProducts from "../case_study_hero_connected_products/case_study_hero_connected_products";
import { innerStyle, wrapperStyle } from "./case_study_hero_inner.module.scss";

const MARGIN_MD = { marginBottom: "lg" };
const REPORT_TITLE_MARGIN = { marginBottom: "lg" };
function CaseStudyHeroInner({
  h1SubtitleText,
  h2Title,
  connectedProducts,
  connectedProductsText,
  textColor,
}) {
  return (
    <div className={wrapperStyle}>
      <Contain>
        <div className={innerStyle}>
          {/* ————— HEADINGS ————— */}
          {h1SubtitleText && (
            <Heading
              isCentered
              isUppercase
              color={textColor}
              seoLevel={1}
              visualLevel={6}
              margin={MARGIN_MD}
            >
              {h1SubtitleText}
            </Heading>
          )}
          {h2Title && (
            <Heading
              isCentered
              isUppercase
              color={textColor}
              seoLevel={2}
              visualLevel={1}
              margin={REPORT_TITLE_MARGIN}
            >
              {h2Title}
            </Heading>
          )}

          {/* ————— CONNECTED PRODUCTS TAG/LINK LIST ————— */}
          <CaseStudyHeroConnectedProducts
            {...{ connectedProducts, connectedProductsText, textColor }}
          />
        </div>
      </Contain>
    </div>
  );
}

CaseStudyHeroInner.propTypes = {
  h1SubtitleText: PropTypes.string,
  h2Title: PropTypes.string,
  connectedProductsText: PropTypes.string,
  connectedProducts: BLOK_ARRAY,
  clientLogo: BLOK_ARRAY,
  trueLayerLogo: BLOK_ARRAY,

  textColor: COLOR,
};

CaseStudyHeroInner.defaultProps = {
  h1SubtitleText: null,
  h2Title: null,
  connectedProductsText: null,
  connectedProducts: null,
  textColor: null,
  clientLogo: null,
  trueLayerLogo: null,
};

export default CaseStudyHeroInner;
