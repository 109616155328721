import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Column from "~components/atoms/column/column";
import Gradient from "~components/atoms/gradient/gradient";
import Heading from "~components/atoms/heading/heading";
import Wave from "~components/atoms/wave/wave";
import Form from "~components/organisms/form/form";
import {
  bgImageLeftStyle,
  bgImageRightStyle,
  bgImageWrapperStyle,
  formWrapperStyle,
  subtitleStyle,
  wrapperStyle,
} from "./rsc_hb_in_page_contact_form.module.scss";

// ToDo: (AMG) don't define colors as JS objects
const COLUMN_SPAN = { XXL: "6", M: "8", S: "10" };
const WHITE_COLOR_STYLE = { color: "#fff" };

// ToDo: (AMG) Merge `RscHbInPageContactForm` & `InPageContactForm`

export default function RscHbInPageContactForm({
  formId,
  formLabel,
  lang,
  subtitle,
  title,
}) {
  return (
    <div data-testid="rsc-hb-in-page-contact-form" className={wrapperStyle}>
      <Wave wave={3} flip position="top" />
      <Gradient gradient="default" />
      <>
        <div className={bgImageLeftStyle}>
          <StaticImage
            className={bgImageWrapperStyle}
            src="../../../../static/images/resource_hub/in_page_contact_form_left-2x.png"
            alt="Background image"
            placeholder="none"
          />
        </div>
        <div className={bgImageRightStyle}>
          <StaticImage
            className={bgImageWrapperStyle}
            src="../../../../static/images/resource_hub/in_page_contact_form_right-2x.png"
            alt="Background image"
            placeholder="none"
          />
        </div>
      </>

      <div id="form" className={formWrapperStyle}>
        <Column span={COLUMN_SPAN}>
          <Heading
            color="white"
            balanceText
            visualLevel={4}
            seoLevel={3}
            isCentered
            data-testid="rsc-hb-in-page-contact-form-title"
          >
            {title}
          </Heading>
          <div className={subtitleStyle}>
            <Heading
              color="white"
              balanceText
              visualLevel="6"
              isExtraBold={false}
              isCentered
              data-testid="rsc-hb-in-page-contact-form-subtitle"
            >
              {subtitle}
            </Heading>
          </div>
          <Form
            backgroundColor="dark"
            buttonColor="yellow"
            errorColor="pink"
            formConversionType="primary_conversion"
            formId={formId}
            formLabel={formLabel}
            isCentered
            lang={lang}
            layout="multiLine"
            portalId={process.env.HUBSPOT_PORTAL_ID}
            successColor={WHITE_COLOR_STYLE}
          />
        </Column>
      </div>
    </div>
  );
}

RscHbInPageContactForm.defaultProps = {
  lang: "en-gb",
  subtitle: "Talk to an expert",
  title: "Ready to get started?",
  formLabel: "resource_hub_contact_form",
};

RscHbInPageContactForm.propTypes = {
  formId: PropTypes.string.isRequired,
  lang: PropTypes.oneOf([
    "",
    "en-gb",
    "de-de",
    "it-it",
    "es-es",
    "fr-fr",
    "en-ie",
    "en-au",
  ]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  formLabel: PropTypes.string,
};
