import React from "react";
import Contain from "~components/atoms/contain/contain";
import Video from "~components/molecules/video/video/video";
import { BLOK_ARRAY } from "../../../../prop_types";

function SRVideoPlayer({ video: [video] }) {
  return (
    <Contain>
      <Video {...video} />
    </Contain>
  );
}

SRVideoPlayer.propTypes = {
  video: BLOK_ARRAY,
};

SRVideoPlayer.defaultProps = {
  video: null,
};

export default SRVideoPlayer;
