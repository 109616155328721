import React from "react";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import RichTextWithBloks from "~components/molecules/rich_text/rich_text_with_bloks";
import { BLOK_ARRAY } from "../../../../prop_types";
import { wrapperStyle } from "./sr_body_text.module.scss";

export default function SRBodyText({ body: bodyArray, heading: headingArray }) {
  const [body] = bodyArray || [];
  const [heading] = headingArray || [];
  return (
    <div className={wrapperStyle}>
      <Contain small>
        {heading && <Heading {...heading} />}
        {body && <RichTextWithBloks {...body} />}
      </Contain>
    </div>
  );
}

SRBodyText.defaultProps = {
  body: [],
  heading: "",
};

SRBodyText.propTypes = {
  body: BLOK_ARRAY,
  heading: BLOK_ARRAY,
};
