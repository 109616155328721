import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Card from "~components/atoms/card/card";
import Contain from "~components/atoms/contain/contain";
import { MEDIA } from "../../../../prop_types";
import SRImageWrapperInner from "./components/sr_image_wrapper_inner/sr_image_wrapper_inner";
import { wrapperStyle } from "./sr_image_wrapper.module.scss";

function SRImageWrapper({
  image,
  hasCard,
  hasPadding,
  minWidth,
  title,
  small,
  ...rest
}) {
  if (hasCard) {
    return (
      <SbEditable content={rest}>
        <div className={wrapperStyle}>
          <Contain small={small}>
            <Card isFullWidth overflowHidden>
              <SRImageWrapperInner
                title={title}
                image={image}
                minWidth={minWidth}
                hasPadding={hasPadding}
              />
            </Card>
          </Contain>
        </div>
      </SbEditable>
    );
  }

  return (
    <SbEditable content={rest}>
      <div className={wrapperStyle}>
        <Contain small>
          <SRImageWrapperInner
            title={title}
            image={image}
            minWidth={minWidth}
            small={small}
            hasPadding={hasPadding}
          />
        </Contain>
      </div>
    </SbEditable>
  );
}

SRImageWrapper.propTypes = {
  image: MEDIA,
  hasPadding: PropTypes.bool,
  hasCard: PropTypes.bool,
  small: PropTypes.bool,
  minWidth: PropTypes.string,
  title: PropTypes.string,
};

SRImageWrapper.defaultProps = {
  image: null,
  small: true,
  hasPadding: false,
  hasCard: false,
  minWidth: null,
  title: null,
};

export default SRImageWrapper;
