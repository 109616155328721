import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import Tag from "~components/molecules/tags/tag";
import { BLOK_ARRAY, COLOR } from "../../../../../../prop_types";
import {
  connectedProductsSectionWrapperStyle,
  connectedProductsTagsWrapperStyle,
} from "./case_study_hero_connected_products.module.scss";

const MARGIN_NONE = { marginBottom: "none", marginTop: "none" };

function CaseStudyHeroConnectedProducts({
  connectedProducts,
  connectedProductsText,
  textColor,
}) {
  return (
    <div className={connectedProductsSectionWrapperStyle}>
      {connectedProductsText && (
        <Heading
          balanceText
          isCentered
          noWrap
          color={textColor}
          seoLevel={2}
          visualLevel={6}
          margin={MARGIN_NONE}
        >
          {connectedProductsText}
          {": "}
        </Heading>
      )}

      <div className={connectedProductsTagsWrapperStyle}>
        {connectedProducts?.length > 0 &&
          connectedProducts.map((product) => {
            return (
              <Tag color="whiteTransparent" key={product.title} {...product} />
            );
          })}
      </div>
    </div>
  );
}

CaseStudyHeroConnectedProducts.propTypes = {
  connectedProductsText: PropTypes.string,
  connectedProducts: BLOK_ARRAY,
  textColor: COLOR,
};

CaseStudyHeroConnectedProducts.defaultProps = {
  connectedProductsText: null,
  connectedProducts: null,
  textColor: null,
};

export default CaseStudyHeroConnectedProducts;
