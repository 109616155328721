import React from "react";
import SbEditable from "storyblok-react";
import Column from "~components/atoms/column/column";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import PhoneMockup from "~components/molecules/phone_mockup/phone_mockup";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../../prop_types";

function SRPhoneMockup({
  heading: headingArray,
  phoneMockups,
  split,
  ...rest
}) {
  const [heading] = headingArray || [];

  if (phoneMockups?.length > 0) {
    return (
      <SbEditable content={rest}>
        <Contain small>
          {heading && <Heading {...heading} />}
          <GridNew split={split}>
            {phoneMockups.map((mockup) => {
              return (
                <Column isContentCentered key={mockup.asset[0]._uid}>
                  <PhoneMockup>
                    <Image {...mockup.asset[0]} />
                  </PhoneMockup>
                </Column>
              );
            })}
          </GridNew>
        </Contain>
      </SbEditable>
    );
  }

  return null;
}

SRPhoneMockup.propTypes = {
  heading: BLOK_ARRAY,
  phoneMockups: BLOK_ARRAY,
  split: RESPONSIVE_VALUE_NEW,
};

SRPhoneMockup.defaultProps = {
  heading: null,
  phoneMockups: null,
  split: { XXL: 1 },
};

export default SRPhoneMockup;
