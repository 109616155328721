import React from "react";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import Gradient from "~components/atoms/gradient/gradient";
import Heading from "~components/atoms/heading/heading";
import Form from "~components/organisms/form/form";
import {
  imageStyle,
  innerStyle,
  outerWrapperStyle,
  wrapperStyle,
} from "./rsc_hb_cta_newsletter.module.scss";

export default function RscHbCtaNewsletter({ title, lang, subtitle, formId }) {
  return (
    <div data-testid="rsc-hb-cta-newsletter" className={outerWrapperStyle}>
      <Card stretch isFullWidth overflowHidden>
        <Gradient gradient="blue" />
        <div className={wrapperStyle}>
          <div className={innerStyle}>
            <Heading visualLevel="4" seoLevel="4">
              {title}
            </Heading>
            <p>{subtitle}</p>
            <div>
              <Form
                formLabel="newsletter_contact_form"
                formConversionType="secondary_conversion"
                portalId={process.env.HUBSPOT_PORTAL_ID}
                formId={formId}
                layout="singleLine"
                lang={lang}
                buttonColor="yellow"
                errorColor="pink"
                backgroundColor="dark"
              />
            </div>
          </div>
          <img
            className={imageStyle}
            alt="Isometric abstract cubes"
            src="/images/resource_hub/resource_hub_default_breaker.png"
          />
        </div>
      </Card>
    </div>
  );
}

RscHbCtaNewsletter.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  formId: PropTypes.string,
  lang: PropTypes.oneOf([
    "",
    "en-gb",
    "de-de",
    "it-it",
    "es-es",
    "fr-fr",
    "en-ie",
    "en-au",
  ]),
};
RscHbCtaNewsletter.defaultProps = {
  title: "Insights straight to your inbox",
  subtitle: "Join 10,000+ subscribers getting the latest open banking news.",
  formId: "6e2c3fe0-6f47-43ca-b4cf-af266f94f761",
  lang: "en-gb",
};
