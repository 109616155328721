import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "~components/molecules/background_image/background_image";
import QuoteLargeCenterAligned from "~components/molecules/quote/quote_large_center_aligned/quote_large_center_aligned";
import { BLOK_ARRAY, MEDIA } from "../../../../prop_types";
import {
  contentWrapperStyle,
  parallaxWrapperStyle,
} from "./sr_quote_banner.module.scss";

function SRQuoteBanner({ quoteText, cover, quoteAttribution }) {
  return (
    <div className={parallaxWrapperStyle}>
      <BackgroundImage image={cover} objectFit="cover" />
      <div className={contentWrapperStyle}>
        <QuoteLargeCenterAligned
          theme="light"
          quoteAttribution={quoteAttribution}
          quoteBody={quoteText}
        />
      </div>
    </div>
  );
}

SRQuoteBanner.propTypes = {
  cover: MEDIA.isRequired,
  quoteText: PropTypes.string.isRequired,
  quoteAttribution: BLOK_ARRAY,
};
SRQuoteBanner.defaultProps = {
  quoteAttribution: null,
};

export default SRQuoteBanner;
